import $ from 'jquery';
// Set global variable
window.$ = window.jQuery = $;

import 'jquery-ui';
import * as L from 'leaflet';
window.L = L;

import 'esri-leaflet';
import * as ELG from 'esri-leaflet-geocoder';
window.ELG = ELG;

import * as moment from 'moment';
window.moment =  moment;

require('./bootstrap');
require('jquery-repeater-form');
require('bootstrap-daterangepicker');
require('./main');
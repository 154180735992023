var myModal = document.getElementById('exampleModal');

const getlonglatByAddress = async (address, marker) => {
    let longlat;
    const request = await fetch('https://nominatim.openstreetmap.org/search?format=json&q='+address, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    const requestJson = await request.json();
    if(requestJson) {
        
        if(requestJson.length > 0) {
            let item = requestJson[0];
            longlat = [parseFloat(item.lat), parseFloat(item.lon)];
            if (typeof(marker)==='undefined'){
                marker = new L.marker(longlat, { draggable: true });
                marker.addTo(map);
            } else {
                marker.setLatLng(longlat);
            }

            let data = {
                'latlng' : {
                    'lat'   : longlat[0],
                    'lng'   : longlat[1]
                }
            }
            updateLngLat(data);
        }
        
    }


    return longlat;
}

window.getMaps = (el, token)  => {
    let LatUserParse = el.querySelector('.latitude').value;
    let LngUserParse = el.querySelector('.longitude').value;

    let city = el.querySelector('[name*=city]').text;
    let addressParse = city;

    let latUser = parseFloat(LatUserParse);
    let lngUser = parseFloat(LngUserParse);

    if(LatUserParse === null || LatUserParse === 0 || LngUserParse === null || LngUserParse == 0) {
        latUser = -7.7973104;
        lngUser = 110.3749208;
    }

    var map = L.map('map',{
        zoom: 1.5,
    }).setView([latUser, lngUser], 18);

    L.control.scale().addTo(map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    var icon = new L.Icon.Default();
    icon.options.shadowSize = [0,0];

    var marker = L.marker([latUser, lngUser], { draggable: true, icon : icon }).addTo(map);
    var popup = marker.bindPopup('Click on map or drag a marker.');
    popup.openPopup();

    if( (LatUserParse === null || LatUserParse === 0 || LngUserParse === null || LngUserParse == 0) && addressParse) {
        getlonglatByAddress(addressParse, marker);
    }

    var searchControl = ELG.geosearch({
        position: 'topright',
        placeholder: 'Enter an address or place e.g. 1 York St',
        useMapBounds: false,
        providers : [ELG.arcgisOnlineProvider({
            apikey: token, 
            nearby: {
              lat: latUser,
              lng: lngUser
            }
          })]
    }).addTo(map);

    searchControl.on('results', function(data){
        for (var i = data.results.length - 1; i >= 0; i--) {
            if (typeof(marker)==='undefined'){
                marker = new L.marker(data.results[i].latlng, { draggable: true });
                marker.addTo(map);
            }
            else {
                marker.setLatLng(data.results[i].latlng);
            }
            updateLngLat(data.results[i]);
        }
    });

    setTimeout(function(){$('.pointer').fadeOut('slow');},3400);


    // Comment out the below code to see the difference.
    myModal.addEventListener('shown.bs.modal', function (e) {
        map.invalidateSize();
    });

    map.on('click', function(e) {
        if (typeof(marker)==='undefined'){
            marker = new L.marker(e.latlng, { draggable: true });
            marker.addTo(map);
        }
        else {
            marker.setLatLng(e.latlng);
        }

        updateLngLat(e);

    });

    marker.on('dragend', function(e) {
        var position = marker.getLatLng();
        marker.setLatLng(position, {
            draggable: 'true'
        }).bindPopup(position).update();
        map.panTo(position);

        updateLngLat(e);
        
    });

    document.getElementById('save-pin-location').addEventListener('click', function(e) {
        // this.querySelector('[name=latitude]').value = this.querySelector('[name=latitude]').getAttribute('data-lat');
        // this.querySelector('[name=longitude]').value = this.querySelector('[name=longitude]').getAttribute('data-lng');
        $('#exampleModal').modal('hide');
    });
}

const updateLngLat = (e) => {
    let index = document.querySelector('#exampleModal').getAttribute('data-index') ? parseInt(document.querySelector('#exampleModal').getAttribute('data-index')) : 0;
    let el = document.querySelectorAll('[data-repeater-item]').length > 0 ? document.querySelectorAll('[data-repeater-item]')[index-1] : document;
    if (el.querySelector('.latitude')) {
        el.querySelector('.latitude').setAttribute('data-lat', e.latlng.lat);
        el.querySelector('.latitude').value = e.latlng.lat;
    }

    if(el.querySelector('.longitude')) {
        el.querySelector('.longitude').value = e.latlng.lng;
    }
}

window.callMaps = (el) => {
    const data = {};

    fetch('/maps-token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token
        },
        body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(token => {
        getProvince(el);
        getMaps(el, token);
    })
    .catch((error) => {
        console.log('Error:', error);
    });
}
function loadDatePicker() {
    $('.input-date-picker').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        minYear: 1950,
        maxYear: parseInt(moment().subtract(17, 'years').format('YYYY'),10),
        locale: {
            format: 'YYYY-MM-DD'
        }
        
    });
    $('.input-date-picker-married').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        minYear: 1950,
        maxYear: parseInt(moment().format('YYYY'),10),
        locale: {
            format: 'YYYY-MM-DD'
        }
        
    });
    $('.input-date-picker').on('apply.daterangepicker', function(ev, picker) {
        this.value = picker.startDate.format('YYYY-MM-DD');
        $(this).change();
    });
    $('.input-date-picker-married').on('apply.daterangepicker', function(ev, picker) {
        this.value = picker.startDate.format('YYYY-MM-DD');
        $(this).change();
    });
}
document.addEventListener('livewire:load', function () {
    loadDatePicker();
})

window.addEventListener('loadDatePicker', event => {
    loadDatePicker(event);
});
window.getProvince = (el, selector = '') => {
    console.log('.province'+selector)
    el.querySelector('.province'+selector).addEventListener('change', function (e) {

        getCity(el, this.value, selector);
        
    });

    const data = {};

    fetch('/data/province', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token
        },
        body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(res => {
        let select = el.querySelector('.province'+selector);
        let value = select.getAttribute('data-value');
        res.provinces.forEach(item => {
            var option = document.createElement('option');
            option.text = item.province_name;
            option.value = item.id_province;
            if (value == item.id_province) {
                option.selected = true;
            }
            select.appendChild(option);
        });
        const event = new Event('change');
        select.dispatchEvent(event);

    })
    .catch((error) => {
        console.log('Error:', error);
    });
}


window.getCity = (el, province, selector = '') => {
    const data = {
        'id_province' : province
    };


    fetch('/data/province', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token
        },
        body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(res => {
        let select = el.querySelector('.city'+selector);
        let value = select.getAttribute('data-value');

        select.innerHTML = '<option value="">Select City</option>';
        res.cities.forEach(item => {
            var option = document.createElement('option');
            option.text = item.city_name;
            option.value = item.id_city;
            if (value == item.id_city) {
                option.selected = true;
            }
            select.appendChild(option);
        });

        const event = new Event('change');
        select.dispatchEvent(event);
    })
    .catch((error) => {
        console.log('Error:', error);
    });
}